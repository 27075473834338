import { useTranslation } from "react-i18next";

import { useToast } from "@aviary";
import { l } from "@shared/locales/i18n";

interface CopyToClipboardParams {
  successMessage?: string;
  errorMessage?: string;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

const useCopyToClipboard = () => {
  const { t } = useTranslation();
  const { makeToast } = useToast();

  const copyToClipboard = async (
    content: string,
    { successMessage, errorMessage, onSuccess, onError }: CopyToClipboardParams = {}
  ) => {
    try {
      await navigator.clipboard.writeText(content);

      if (onSuccess) {
        onSuccess();
      } else {
        makeToast("success", successMessage ?? t(l.sharedCommon.CopiedToClipboard));
      }
    } catch (error) {
      if (onError) {
        onError(error);
      } else {
        makeToast("error", errorMessage ?? t(l.sharedCommon.FailedToCopyText));
      }
    }
  };

  return copyToClipboard;
};

export { useCopyToClipboard };
