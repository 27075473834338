import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Link, Separator, Spacer, Typography } from "@aviary";
import { l } from "@shared/locales/i18n";

import { QrCode } from "../../components/QrCode/QrCode";

import * as styles from "./EnableWithAuthenticator.styles";

interface Props {
  qrCodeUrl: string;
  manualSetupCode: string;
  children: ReactNode;
  isModal?: boolean;
}

const EnableWithAuthenticator = ({
  qrCodeUrl,
  manualSetupCode,
  children,

  isModal,
}: Props) => {
  const { t } = useTranslation();

  const renderTitle = () => {
    if (isModal) {
      return (
        <>
          <Typography type="h4">{t(l.mfa.enableWithAuthenticator.title)}</Typography>
          <Typography>
            {t(l.mfa.enableWithAuthenticator.subtitle)}{" "}
            <Link
              isColor="system"
              href="https://support.fullscript.com/articles/securing-your-practitioner-account-with-multi-factor-verification/#enabling-multi-factor-authentication-authenticator-app-option"
            >
              {t(l.sharedCommon.learnMore)}
            </Link>
          </Typography>
          <Spacer height="one" />
        </>
      );
    }

    return (
      <div css={styles.header}>
        <Typography type="h1">{t(l.mfa.enableWithAuthenticator.title)}</Typography>
      </div>
    );
  };

  return (
    <>
      {renderTitle()}
      <Typography isSecondaryWeight isMarginless>
        {t(l.mfa.step1)}
      </Typography>
      <>
        <Typography>{t(l.mfa.enableWithAuthenticator.scanTheQrCode)}</Typography>
        <Spacer height="quarter" />
        <QrCode url={qrCodeUrl} manualSetupCode={manualSetupCode} />
      </>
      <Separator css={styles.separator} />
      <Typography isSecondaryWeight isMarginless>
        {t(l.mfa.step2)}
      </Typography>
      <Typography>{t(l.mfa.enterCodeFromAuthenticator)}</Typography>
      {children}
      <Spacer height="one" />
    </>
  );
};

export { EnableWithAuthenticator };
