import { css } from "@emotion/react";

export const header = css`
  text-align: center;
  margin-bottom: 28px;
`;

export const seperator = css`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
