import { gql } from "@apollo/client";

const ResendSetupMFAPasscode_Shared_Mutation = gql`
  mutation ResendSetupMFAPasscode_Shared_Mutation(
    $input: AuthUserTwoFactorAuthenticationResendEmailSetupInput!
  ) {
    auth {
      userResendEmailSetupTwoFactorAuthentication(input: $input) {
        sent
        errors {
          message
        }
      }
    }
  }
`;

export { ResendSetupMFAPasscode_Shared_Mutation };
