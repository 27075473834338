// @ts-strict-ignore
import type { TwoFactorAuthenticationMethods as MFAMethods } from "@shared/types/graphqlGenerated";

import { useMfaSharedQuery } from "./MFA.query.generated";

interface MFAData {
  isMFAEnabled: boolean;
  isLocked: boolean;
  hasMultiPlatformWarning: boolean;
  otpMethod: MFAMethods;
  phoneLast4: string;
  email: string;
  emailMasked: string;
  loading: boolean;
}

const useMFAData = (): MFAData => {
  const { data, loading } = useMfaSharedQuery();

  return {
    isMFAEnabled: data?.currentUser?.twoFactorAuthentication?.isEnabled,
    isLocked: data?.currentUser?.twoFactorAuthentication?.isLocked,
    hasMultiPlatformWarning: data?.currentUser?.hasMultipleStorePlatforms,
    otpMethod: data?.currentUser?.twoFactorAuthentication?.otpMethod,
    phoneLast4: data?.currentUser?.twoFactorAuthentication?.phoneLast4,
    email: data?.currentUser?.twoFactorAuthentication?.email,
    emailMasked: data?.currentUser?.twoFactorAuthentication?.emailMasked,
    loading,
  };
};

export { useMFAData };
