import type { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Spacer, Typography } from "@aviary";
import { l } from "@shared/locales/i18n";

import * as styles from "./SecureYourAccount.styles";

interface Props {
  children: ReactNode;
  isSkippable?: boolean;
  isChangeFlow?: boolean;
  isModal?: boolean;
}

const SecureYourAccount = ({ children, isSkippable, isChangeFlow, isModal }: Props) => {
  const { t } = useTranslation();

  const renderContent = () => {
    if (isChangeFlow) {
      return (
        <>
          <Typography type="h4">{t(l.mfa.chooseAuthenticationMethod.changeTitle)}</Typography>
          <Typography>{t(l.mfa.chooseAuthenticationMethod.subtitle)}</Typography>
        </>
      );
    }

    if (isModal) {
      return (
        <>
          <Typography css={styles.title} type="h4">
            {t(l.mfa.chooseAuthenticationMethod.title)}
          </Typography>
          <Typography>{t(l.mfa.chooseAuthenticationMethod.subtitle)}</Typography>
          <Typography isMarginless>{renderMandatoryWarning()}</Typography>
        </>
      );
    }

    return (
      <div css={styles.header}>
        <Typography css={styles.title} type="h1" isMarginless>
          {t(l.mfa.chooseAuthenticationMethod.title)}
        </Typography>
        <Typography isMarginless>{t(l.mfa.chooseAuthenticationMethod.subtitle)}</Typography>
        <Spacer height="oneHalf" />
        <Typography isMarginless>{renderMandatoryWarning()}</Typography>
      </div>
    );
  };

  const renderMandatoryWarning = () => {
    if (isSkippable) {
      return (
        <Trans i18nKey={l.mfa.chooseAuthenticationMethod.willBeMandatorySoon}>
          <strong>This will be mandatory soon.</strong> Set up today to add an extra layer of
          security.
        </Trans>
      );
    }

    return t(l.mfa.chooseAuthenticationMethod.thisIsMandatory);
  };

  return (
    <>
      {renderContent()}
      <Spacer height="double" />
      {children}
    </>
  );
};

export { SecureYourAccount };
