import type * as Types from '@shared/types/graphqlGenerated.ts';

import * as Operations from './MFA.query';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MfaSharedQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MfaSharedQuery = { currentUser?: Types.Maybe<(
    Pick<Types.User, 'id' | 'hasMultipleStorePlatforms'>
    & { twoFactorAuthentication?: Types.Maybe<Pick<Types.TwoFactorAuthentication, 'id' | 'isEnabled' | 'isLocked' | 'otpMethod' | 'phoneLast4' | 'email' | 'emailMasked'>> }
  )> };



/**
 * __useMfaSharedQuery__
 *
 * To run a query within a React component, call `useMfaSharedQuery` and pass it any options that fit your needs.
 * When your component renders, `useMfaSharedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMfaSharedQuery({
 *   variables: {
 *   },
 * });
 */
export function useMfaSharedQuery(baseOptions?: Apollo.QueryHookOptions<MfaSharedQuery, MfaSharedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MfaSharedQuery, MfaSharedQueryVariables>(Operations.MFA_Shared_Query, options);
      }
export function useMfaSharedQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MfaSharedQuery, MfaSharedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MfaSharedQuery, MfaSharedQueryVariables>(Operations.MFA_Shared_Query, options);
        }
export function useMfaSharedQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MfaSharedQuery, MfaSharedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MfaSharedQuery, MfaSharedQueryVariables>(Operations.MFA_Shared_Query, options);
        }
export type MfaSharedQueryHookResult = ReturnType<typeof useMfaSharedQuery>;
export type MfaSharedQueryLazyQueryHookResult = ReturnType<typeof useMfaSharedQueryLazyQuery>;
export type MfaSharedQuerySuspenseQueryHookResult = ReturnType<typeof useMfaSharedQuerySuspenseQuery>;