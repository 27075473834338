import { gql } from "@apollo/client";

const SetupMFA_Shared_Mutation = gql`
  mutation SetupMFA_Shared_Mutation($input: AuthUserTwoFactorAuthenticationSetupInput!) {
    auth {
      userSetupTwoFactorAuthentication(input: $input) {
        clientMutationId
        twoFactorAuthentication {
          id
          isEnabled
          isLocked
          otpMethod
          emailMasked
          phoneLast4
        }
        qrCodeUrl
        manualSetupCode
        errors {
          message
        }
      }
    }
  }
`;

export { SetupMFA_Shared_Mutation };
