const isLengthValid = (email: string) => !!email;
const isEmailAtValid = (email: string) => email.includes("@");
const isEmailDotValid = (email: string) => email.includes(".");

const isEmailRegexValid = (email: string, regex: RegExp) => regex.test(email);

const isBasicEmailValid = (email: string) => {
  return isLengthValid(email) && isEmailAtValid(email);
};

const isEmailValid = (email: string, regex: RegExp) => {
  return isBasicEmailValid(email) && isEmailDotValid(email) && isEmailRegexValid(email, regex);
};

export { isBasicEmailValid, isEmailValid, isLengthValid, isEmailAtValid, isEmailDotValid };
