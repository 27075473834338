import { gql } from "@apollo/client";

const ResendEditMFAPasscode_Shared_Mutation = gql`
  mutation ResendEditMFAPasscode_Shared_Mutation(
    $input: AuthUserTwoFactorAuthenticationResendEditInput!
  ) {
    auth {
      userResendEditTwoFactorAuthentication(input: $input) {
        sent
        errors {
          message
        }
      }
    }
  }
`;

export { ResendEditMFAPasscode_Shared_Mutation };
