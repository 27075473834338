// @ts-strict-ignore
import { TwoFactorAuthenticationMethods as AuthMethod } from "@shared/types/graphqlGenerated";

interface CurrentMFAProgress {
  currentStep: MFAStepDetails;
  handleStepChange: (isPrevious?: boolean) => void;
  isNextDisabled: boolean;
  isLoading: boolean;
  mfaToken: string;
  redirectPath?: string;
}

type MFAStep =
  | "chooseAuthenticationMethod"
  | "downloadAuthenticator"
  | "enterMobilePhone"
  | "enableWithSMS"
  | "enableWithEmail"
  | "enableWithAuthenticator"
  | "displayBackupCode";

type MFAStepDetails = {
  step: MFAStep;
  nextStep: MFAStep;
  previousStep: MFAStep;
  nextLabelOverride: string | null;
  action: "enableMFA" | "setupMFA" | null;
};

type AuthFlow = {
  authMethod: AuthMethod;
  steps: MFAStepDetails[];
};

const smsMFASetupSteps: MFAStepDetails[] = [
  {
    step: "chooseAuthenticationMethod",
    nextStep: "enterMobilePhone",
    previousStep: null,
    nextLabelOverride: null,
    action: null,
  },
  {
    step: "enterMobilePhone",
    nextStep: "enableWithSMS",
    previousStep: "chooseAuthenticationMethod",
    nextLabelOverride: "Send code",
    action: "setupMFA",
  },
  {
    step: "enableWithSMS",
    nextStep: "displayBackupCode",
    previousStep: "enterMobilePhone",
    nextLabelOverride: "Confirm",
    action: "enableMFA",
  },
  {
    step: "displayBackupCode",
    nextStep: null,
    previousStep: "enableWithSMS",
    nextLabelOverride: "Done",
    action: null,
  },
] as const;

const emailMFASetupSteps: MFAStepDetails[] = [
  {
    step: "chooseAuthenticationMethod",
    nextStep: "enableWithEmail",
    previousStep: null,
    nextLabelOverride: null,
    action: "setupMFA",
  },
  {
    step: "enableWithEmail",
    nextStep: "displayBackupCode",
    previousStep: "chooseAuthenticationMethod",
    nextLabelOverride: "Confirm",
    action: "enableMFA",
  },
  {
    step: "displayBackupCode",
    nextStep: null,
    previousStep: "enableWithEmail",
    nextLabelOverride: "Done",
    action: null,
  },
] as const;

const authenticatorMFASetupSteps: MFAStepDetails[] = [
  {
    step: "chooseAuthenticationMethod",
    nextStep: "downloadAuthenticator",
    previousStep: null,
    nextLabelOverride: null,
    action: null,
  },
  {
    step: "downloadAuthenticator",
    nextStep: "enableWithAuthenticator",
    previousStep: "chooseAuthenticationMethod",
    nextLabelOverride: null,
    action: "setupMFA",
  },
  {
    step: "enableWithAuthenticator",
    nextStep: "displayBackupCode",
    previousStep: "downloadAuthenticator",
    nextLabelOverride: "Confirm",
    action: "enableMFA",
  },
  {
    step: "displayBackupCode",
    nextStep: null,
    previousStep: "enableWithAuthenticator",
    nextLabelOverride: "Done",
    action: null,
  },
] as const;

const smsAuthFlow: AuthFlow = {
  authMethod: AuthMethod.Sms,
  steps: smsMFASetupSteps,
};

const emailAuthFlow: AuthFlow = {
  authMethod: AuthMethod.Email,
  steps: emailMFASetupSteps,
};

const authenticatorAuthFlow: AuthFlow = {
  authMethod: AuthMethod.App,
  steps: authenticatorMFASetupSteps,
};

export { smsAuthFlow, emailAuthFlow, authenticatorAuthFlow };
export type { AuthFlow, MFAStepDetails, CurrentMFAProgress };
