import type { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Link, Separator, Spacer, Typography } from "@aviary";
import { l } from "@shared/locales/i18n";

import { ResendOtp } from "../../components/ResendOtp/ResendOtp";

import * as styles from "./EnableWithEmail.styles";

interface Props {
  emailMasked: string;
  children: ReactNode;
  isVerifyChangeFlow?: boolean;
  isModal?: boolean;
}

const EnableWithEmail = ({ emailMasked, children, isVerifyChangeFlow, isModal }: Props) => {
  const { t } = useTranslation();

  const renderTitle = () => {
    if (isVerifyChangeFlow) {
      return null;
    }

    if (isModal) {
      return (
        <>
          <Typography type="h4">{t(l.mfa.enableWithEmail.title)}</Typography>
          <Typography isMarginless>{t(l.mfa.enableWithEmail.subtitle)}</Typography>
          <Typography>
            <Link
              isColor="system"
              href="https://support.fullscript.com/articles/securing-your-practitioner-account-with-multi-factor-verification/#enabling-multi-factor-authentication-sms-option"
            >
              {t(l.sharedCommon.learnMore)}
            </Link>
          </Typography>
          <Spacer height="one" />
        </>
      );
    }

    return (
      <div css={styles.header}>
        <Typography type="h1">{t(l.mfa.enableWithEmail.title)}</Typography>
      </div>
    );
  };

  return (
    <>
      {renderTitle()}
      <Typography isSecondaryWeight isMarginless>
        {t(l.mfa.step1)}
      </Typography>
      <Typography>
        <Trans i18nKey={l.mfa.enableWithEmail.codeSentToEmail} values={{ emailMasked }} />
      </Typography>
      <ResendOtp email={emailMasked} isVerifyChangeFlow={isVerifyChangeFlow} />
      <Separator css={styles.separator} />
      <Typography isSecondaryWeight isMarginless>
        {t(l.mfa.step2)}
      </Typography>
      <Typography>{t(l.mfa.enableWithEmail.enterCodeFromEmail)}</Typography>
      {children}
      <Spacer height="one" />
    </>
  );
};

export { EnableWithEmail };
