import type * as Types from '@shared/types/graphqlGenerated.ts';

import * as Operations from './ResendEditMFAPasscode.mutation';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResendEditMfaPasscodeSharedMutationVariables = Types.Exact<{
  input: Types.AuthUserTwoFactorAuthenticationResendEditInput;
}>;


export type ResendEditMfaPasscodeSharedMutation = { auth?: Types.Maybe<{ userResendEditTwoFactorAuthentication?: Types.Maybe<(
      Pick<Types.AuthUserTwoFactorAuthenticationResendEditPayload, 'sent'>
      & { errors: Pick<Types.AuthUserTwoFactorAuthenticationResendEditError, 'message'> }
    )> }> };


export type ResendEditMfaPasscodeSharedMutationMutationFn = Apollo.MutationFunction<ResendEditMfaPasscodeSharedMutation, ResendEditMfaPasscodeSharedMutationVariables>;

/**
 * __useResendEditMfaPasscodeSharedMutation__
 *
 * To run a mutation, you first call `useResendEditMfaPasscodeSharedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEditMfaPasscodeSharedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEditMfaPasscodeSharedMutation, { data, loading, error }] = useResendEditMfaPasscodeSharedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendEditMfaPasscodeSharedMutation(baseOptions?: Apollo.MutationHookOptions<ResendEditMfaPasscodeSharedMutation, ResendEditMfaPasscodeSharedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendEditMfaPasscodeSharedMutation, ResendEditMfaPasscodeSharedMutationVariables>(Operations.ResendEditMFAPasscode_Shared_Mutation, options);
      }
export type ResendEditMfaPasscodeSharedMutationHookResult = ReturnType<typeof useResendEditMfaPasscodeSharedMutation>;
export type ResendEditMfaPasscodeSharedMutationMutationOptions = Apollo.BaseMutationOptions<ResendEditMfaPasscodeSharedMutation, ResendEditMfaPasscodeSharedMutationVariables>;