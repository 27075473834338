import { Trans, useTranslation } from "react-i18next";

import { Link, Spacer, Typography, useToast } from "@aviary";
import {
  useResendEditMfaPasscodeSharedMutation as useResendEditMFAPasscode,
  type ResendEditMfaPasscodeSharedMutation as ResendEditMFAPasscodeData,
} from "@shared/MultifactorAuthentication/data/ResendEditMFAPasscode.mutation.generated";
import {
  useResendSetupMfaPasscodeSharedMutation as useResendMFAPasscode,
  type ResendSetupMfaPasscodeSharedMutation as ResendMFAPasscodeData,
} from "@shared/MultifactorAuthentication/data/ResendSetupMFAPasscode.mutation.generated";
import { l } from "@shared/locales/i18n";
import { useLocation } from "@shared/react-router-dom/react-router-dom";

import * as styles from "./ResendOtp.styles";

interface Props {
  phoneLast4?: string;
  email?: string;
  isVerifyChangeFlow?: boolean;
}

interface HistoryState {
  mfaToken?: string;
}

const ResendOtp = ({ phoneLast4, isVerifyChangeFlow, email }: Props) => {
  const { state }: { state: HistoryState } = useLocation();

  const { t } = useTranslation();
  const { makeToast } = useToast();

  const otpMethodName = phoneLast4 ? "text message" : "email";

  const [resendPasscode, { loading: loadingResendPasscode }] = useResendMFAPasscode({
    variables: {
      input: {
        token: state?.mfaToken,
      },
    },
    onCompleted: (data: ResendMFAPasscodeData) => {
      const hasErrors = data?.auth?.userResendEmailSetupTwoFactorAuthentication?.errors?.message;
      const isSent = data?.auth?.userResendEmailSetupTwoFactorAuthentication?.sent;

      if (hasErrors || !isSent) {
        return makeToast("error", t(l.mfa.resendOtp.otpNotSent, { otpMethodName }));
      }

      if (isSent) {
        if (otpMethodName === "email") {
          makeToast("success", t(l.mfa.resendOtp.emailSent, { email }));
        } else if (otpMethodName === "text message") {
          makeToast("success", t(l.mfa.resendOtp.smsSent, { phoneLast4 }));
        }
      }
    },
    onError: ({ message }) => makeToast("error", message),
  });

  const [resendEditPasscode, { loading: loadingResendEditPasscode }] = useResendEditMFAPasscode({
    variables: {
      input: {},
    },
    onCompleted: (data: ResendEditMFAPasscodeData) => {
      const hasErrors = data?.auth?.userResendEditTwoFactorAuthentication?.errors?.message;
      const isSent = data?.auth?.userResendEditTwoFactorAuthentication?.sent;

      if (hasErrors || !isSent) {
        return makeToast("error", t(l.mfa.resendOtp.otpNotSent, { otpMethodName }));
      }

      if (isSent) {
        if (otpMethodName === "email") {
          makeToast("success", t(l.mfa.resendOtp.emailSent, { email }));
        } else if (otpMethodName === "text message") {
          makeToast("success", t(l.mfa.resendOtp.smsSent, { phoneLast4 }));
        }
      }
    },
    onError: ({ message }) => makeToast("error", message),
  });

  const handleResendPasscode = () => {
    if (loadingResendPasscode || loadingResendEditPasscode) return;

    if (isVerifyChangeFlow) {
      resendEditPasscode();
    } else {
      resendPasscode();
    }
  };

  return (
    <div css={styles.wrapper}>
      <Typography type="footnote" isMarginless>
        <Trans
          i18nKey={
            otpMethodName === "email" ? l.mfa.resendOtp.didntGetEmail : l.mfa.resendOtp.didntGetSMS
          }
          components={{ resendLink: <Link onClick={handleResendPasscode} /> }}
        />
      </Typography>
      <Spacer height="half" />
      <Typography type="footnote" css={styles.expirationMessage} isMarginless>
        {t(l.mfa.resendOtp.codeWillExpireIn10)}
      </Typography>
    </div>
  );
};

export { ResendOtp };
