import { gql } from "@apollo/client";

const MFA_Shared_Query = gql`
  query MFA_Shared_Query {
    currentUser {
      id
      hasMultipleStorePlatforms
      twoFactorAuthentication {
        id
        isEnabled
        isLocked
        otpMethod
        phoneLast4
        email
        emailMasked
      }
    }
  }
`;

export { MFA_Shared_Query };
