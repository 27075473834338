import { Trans } from "react-i18next";

import { NoStyleButton } from "@aviary";
import { l } from "@shared/locales/i18n";

import { noStyleButton } from "./SuggestedEmail.styles";

interface Props {
  suggestedEmail: string;
  handleSuggestedEmailClick: () => void;
}

const SuggestedEmail = ({ suggestedEmail, handleSuggestedEmailClick }: Props) => {
  return (
    <Trans values={{ suggestedEmail }} i18nKey={l.sharedCommon.DidYouMeanThisEmail}>
      Did you mean
      <NoStyleButton
        aria-label={l.sharedCommon.SelectSuggestedEmail}
        css={noStyleButton}
        onClick={handleSuggestedEmailClick}
      >
        {{ values: suggestedEmail }}
      </NoStyleButton>
      ?
    </Trans>
  );
};

export { SuggestedEmail };
