import { QRCodeCanvas } from "qrcode.react";
import { useTranslation } from "react-i18next";

import { Button, Cell, Spacer } from "@aviary";
import { useCopyToClipboard } from "@shared/hooks/useCopyToClipboard/useCopyToClipboard";
import { l } from "@shared/locales/i18n";

import * as styles from "./QrCode.styles";

interface Props {
  url: string;
  manualSetupCode: string;
  isLoading?: boolean;
}

const QrCode = ({ url, manualSetupCode, isLoading }: Props) => {
  const { t } = useTranslation();
  const copyToClipboard = useCopyToClipboard();

  const copyManualSetupCode = async () => {
    await copyToClipboard(manualSetupCode, { successMessage: t(l.mfa.manualSetupCodeCopied) });
  };

  return (
    <div css={styles.qrCodeWrapper}>
      <QRCodeCanvas value={url} size={180} />
      <Spacer height="half" />
      <Cell isMarginless>{manualSetupCode}</Cell>
      <Spacer height="one" />
      <Button
        isOutlined
        isColor="system"
        size="small"
        onClick={copyManualSetupCode}
        isSkeleton={isLoading}
      >
        {t(l.mfa.copyCode)}
      </Button>
    </div>
  );
};

export { QrCode };
