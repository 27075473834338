import type * as Types from '@shared/types/graphqlGenerated.ts';

import * as Operations from './ResendSetupMFAPasscode.mutation';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResendSetupMfaPasscodeSharedMutationVariables = Types.Exact<{
  input: Types.AuthUserTwoFactorAuthenticationResendEmailSetupInput;
}>;


export type ResendSetupMfaPasscodeSharedMutation = { auth?: Types.Maybe<{ userResendEmailSetupTwoFactorAuthentication?: Types.Maybe<(
      Pick<Types.AuthUserTwoFactorAuthenticationResendEmailSetupPayload, 'sent'>
      & { errors: Pick<Types.AuthUserTwoFactorAuthenticationResendEmailSetupError, 'message'> }
    )> }> };


export type ResendSetupMfaPasscodeSharedMutationMutationFn = Apollo.MutationFunction<ResendSetupMfaPasscodeSharedMutation, ResendSetupMfaPasscodeSharedMutationVariables>;

/**
 * __useResendSetupMfaPasscodeSharedMutation__
 *
 * To run a mutation, you first call `useResendSetupMfaPasscodeSharedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendSetupMfaPasscodeSharedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendSetupMfaPasscodeSharedMutation, { data, loading, error }] = useResendSetupMfaPasscodeSharedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendSetupMfaPasscodeSharedMutation(baseOptions?: Apollo.MutationHookOptions<ResendSetupMfaPasscodeSharedMutation, ResendSetupMfaPasscodeSharedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendSetupMfaPasscodeSharedMutation, ResendSetupMfaPasscodeSharedMutationVariables>(Operations.ResendSetupMFAPasscode_Shared_Mutation, options);
      }
export type ResendSetupMfaPasscodeSharedMutationHookResult = ReturnType<typeof useResendSetupMfaPasscodeSharedMutation>;
export type ResendSetupMfaPasscodeSharedMutationMutationOptions = Apollo.BaseMutationOptions<ResendSetupMfaPasscodeSharedMutation, ResendSetupMfaPasscodeSharedMutationVariables>;